.css-bsh4be-MuiTabs-root button {
  border: none !important;
  position: relative;
  width: 50%;
  margin: 0 auto;
}

.css-bsh4be-MuiTabs-root button::before,
.css-bsh4be-MuiTabs-root button::after {
  content: '';
  position: absolute;
  bottom: 0;
  height: 4px;
  width: 60px;
  background-color: #141218;
  border-radius: 10px;
}

.css-bsh4be-MuiTabs-root button.Mui-selected::before,
.css-bsh4be-MuiTabs-root button.Mui-selected::after {
  background-color: #d0bcff;
}
