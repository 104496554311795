@import url("https://fonts.googleapis.com/css2?family=Baskervville&family=Passero+One&display=swap");

@font-face {
  font-family: "Anglodavek";
  src: local("Anglodavek"), url("fonts/Anglodavek-a55E.ttf") format("truetype");
}

@font-face {
  font-family: "BlackCastle";
  src: local("BlackCastle"),
    url("fonts/Blackcastlemf-BG5n.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Quentincaps";
  src: local("Quentincaps"),
    url("fonts/Quentincaps-owxKz.ttf") format("truetype");
  font-display: swap;
}
