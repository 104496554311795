.MuiButtonBase-root.MuiCardActionArea-root {
  position: relative;
  border-radius: 66px;
  background-color: transparent !important;
}

.MuiButtonBase-root.MuiCardActionArea-root:hover {
  background-color: transparent !important;
}

// .MuiButtonBase-root.MuiCardActionArea-root::before {
//   content: '';
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   pointer-events: none;
//   border-radius: 66px;
//   transition: all 0.3s ease;
//   // background-color: rgba(41, 54, 169, 0.4);
//   // filter: blur(2px) !important;
// }
