.panel {
  &__list {
    border-radius: 4px;
  }
}

.MuiListItemButton-root {
  background-color: transparent !important;
}

.MuiListItemButton-root:hover .panel__list__icon img {
  cursor: pointer;
  filter: drop-shadow(0px 0px 4px #fff) drop-shadow(0px 0px 4px #fff);
}

.panel__list__icon img {
  width: 26px !important;
  height: auto !important;
}
