.panelCard.MuiButtonBase-root.MuiCardActionArea-root::before {
  display: none !important;
}

.panelCard.MuiButtonBase-root.MuiCardActionArea-root:hover::before {
  display: none !important;
}
// button.panelCard {
//   content: '';
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   pointer-events: none;
//   border-radius: 66px;
//   transition: all 0.3s ease;
//   //   background-color: rgba(41, 54, 169, 0.4);
//   //   filter: blur(2px) !important;
// }

button.panelCard span.background {
  background-color: transparent;
  height: 100% !important;
  width: 100% !important;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
  border-radius: 50%;
  filter: blur(2px) !important;
}

button.panelCard:hover span.background {
  background-color: rgba(41, 54, 169, 0.4) !important;
  transition: 0.3s ease-in-out;
}

button.panelCard {
  height: 100% !important;
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  position: relative;
  box-sizing: border-box !important;
  z-index: 3;
  cursor: pointer;
}

// button.panelCard .panelCard__item {
//   height: 100% !important;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// button.panelCard .panelCard__item img {
//   width: auto;
//   height: 50px;
// }
